@import url("https://fonts.googleapis.com/css?family=Droid+Sans|Droid+Serif|Muli|PT+Serif|Prociono|Slabo+27px|Source+Sans+Pro|Trirong|Ubuntu|Aref+Ruqaa|Great+Vibes|Philosopher|Roboto+Condensed|Bitter|Source+Sans+Pro|Acme|Raleway|Work+Sans|Tangerine:wght@900|Varela+Round|Montserrat:wght@200|Oleo+Script:wght@700&display=swap");

iframe {
  display: none;
}

@font-face {
  font-family: Visby;
  src: url("./fonts/OTF/VisbyRoundCF-Regular.otf");
}

@font-face {
  font-family: Visby-Bold;
  src: url("./fonts/OTF/VisbyRoundCF-Bold.otf");
}

@font-face {
  font-family: Archia;
  font-style: normal;
  src: url("./fonts/Archia-Regular-Desktop/Archia-Regular.otf");
}

@font-face {
  font-family: GT-Eesti;
  font-style: normal;
  src: url("./fonts/GT-Eesti-Pro-Display-Regular/GT-Eesti-Pro-Display-Regular.otf");
}

@font-face {
  font-family: Work Sans;
  src: url("./fonts/Work_Sans/static/WorkSans-Regular.ttf");
}

@font-face {
  font-family: WorkSans-Semi-Bold;
  src: url("./fonts/Work_Sans/static/WorkSans-SemiBold.ttf");
}

@font-face {
  font-family: WorkSans-Medium;
  src: url("./fonts/Work_Sans/static/WorkSans-Medium.ttf");
}

@font-face {
  font-family: Mulish;
  src: url("./fonts/Mulish/static/Mulish-Regular.ttf");
}

@font-face {
  font-family: Mulish-Extra-Bold;
  src: url("./fonts/Mulish/static/Mulish-Black.ttf");
}

@font-face {
  font-family: Mulish-Bold;
  src: url("./fonts/Mulish/static/Mulish-Bold.ttf");
}

@font-face {
  font-family: Mulish-Light;
  src: url("./fonts/Mulish/static/Mulish-ExtraLight.ttf");
}




@font-face {
  font-family: Mulish-Bold;
  src: url("./fonts/Mulish/static/Mulish-Bold.ttf");
}


@font-face {
  font-family: Biome-Bold;
  src: url("./fonts/Biome/biome-bold.ttf");
}



@font-face {
  font-family: Biome-Light;
  src: url("./fonts/Biome/biome-pro-light.otf");
}







.biomeBold {
  font-family: 'Biome-Bold', sans-serif !important;
}

.biomelight {
  font-family: 'Biome-Light', sans-serif !important;
}

.Montserrat{
  font-family: 'Montserrat', sans-serif !important;
}



.workSan {
  font-family: 'Work Sans', sans-serif !important;
}

.workSanMedium {
  font-family: 'WorkSans-Medium';
}

.workSanBold {
  font-family: 'WorkSans-Semi-Bold', sans-serif !important;
  font-weight: 900;
}

.mulishLight {
  font-family: 'Mulish-Light';
}

.mulishBolder {
  font-family: 'Mulish-Extra-Bold';
}

.mulishBold {
  font-family: 'Mulish-Bold';
}

.f-mulish {
  font-family: 'Mulish', sans-serif;
}

.f-mulish-bolder {
  font-family: 'Mulish', sans-serif;
  font-weight: 900;
}

.f-Varela {
  font-family: 'Varela Round', sans-serif !important;
}

.f-ubuntu {
  font-family: 'Ubuntu', sans-serif !important;
}

.f-Roboto-Condensed {
  font-family: 'Roboto Condensed', sans-serif !important;
}

.f-raleway {
  font-family: Visby, sans-serif !important;
}

.f-visby {
  font-family: Visby !important;
}

.f-visby-bold {
  font-family: Visby-Bold !important;
}

.f-eesti {
  font-family: GT-Eesti !important;
}

.f-archia {
  font-family: Archia !important;
}

.f-acme {
  font-family: 'Acme', sans-serif !important;
}

.f-bitter {
  font-family: 'Bitter', sans-serif !important;
  /* Text color */
}




.primaryBg {
  background: #223890 !important;
}

.secondaryBg {
  background: #BA286F !important;
}

.secondaryColor {
  color: #BA286F !important;
}

.primaryColor {

  color: #223890 !important;
}


.primaryColor2 {

  color: #263C93 !important;
}



.gridBox {
  min-height: 150px;
  box-shadow: 0px 0px 28px 6px rgba(0, 0, 0, 0.09);
}

.gridBox:hover {
  min-height: 150px;
  box-shadow: 0px 0px 28px 6px rgba(0, 0, 0, 0.09);
  background: #08958B;
  color: #fff !important
}

.gridBox2 {
  min-height: 220px;
  border-right: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB
}

.gridBox2:hover {
  box-shadow: 0px 0px 28px 6px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.whiteBox {
  background: #fff;
  min-height: 300px;
  border-right: 0.5px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 20px 50px;
  filter: drop-shadow(0px 0px 66px rgba(0, 0, 0, 0.08));
  box-shadow: -2px -11px 15px rgba(0, 146, 136, 0.15), 2px 11px 15px rgba(51, 51, 51, 0.15);

}

.whiteBox2 {
  background: #fff;
  width: 100%;
  min-height: 250px;
  margin-top: 100px;
  margin-left: -126px;

}

.mockupSecton {

  height: 500px;
  background: linear-gradient(rgba(4, 63, 59, 0.5), rgba(0, 146, 136, 0.877));
  margin-top: 100px;
}

.footer {
  min-height: 400px;
  background: #263C93 20px top right;

  /* filter: blur(225px); */
}

.structure-header {
  height: 74px;
  background: #263C93;
  color: #fff;
  width: 100%;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 15px;
}


.myNav {
  min-height: 100vh;
  display: visible !important;
  width: 250px;
  background: #f9f9f9;
  top: none !important;
  bottom: none !important;
  left: none !important;
  padding-top: 0px;
  overflow-y: scroll;
  float: left !important;
  position: absolute !important;
  border-right: 1px solid #d2d2d2;
  -webkit-box-shadow: 2px 0 0 2px #2c2c2c;
  box-shadow: 2px 0 0 2px #2c2c2c;
}


.mainBody {
  background: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  left: 250px;
  position: fixed;
  overflow-y: scroll;
}
.sideNav {
  width: 100%;
  margin-top: 10px !important;
}
.sideNav ul {
  margin: 0;
  padding: 0;
}
.sideNav ul li {
  display: block;
  height: 50px;
  color: #b1b1b1;
  margin-right: 25px !important;
  margin-bottom: 20px;
}
.sideNav ul li a {
  display: block;
  width: 100%;
  padding: 10px;
  color: #2c2c2c;
  font-family: 'Work Sans';
  font-size: 17px;
  text-decoration: none !important;
}
.sideNav ul li a svg,
.sideNav ul li a i {
  color: #2c2c2c;
  margin-right: 20px;
  width: 30px;
  height: 20px;
  padding-top: 5px;
  padding-left: 20px;
}
.sideNav ul .active,
.sideNav ul li:hover a {
  background: #fff;
  color: #0353a4 !important;
  text-decoration: none !important;
  border-right: 3px solid #0353a4 !important;
  width: 245px;
  margin-right: 55px;
}
.sideNav ul .active svg,
.sideNav ul li:hover a svg,
.sideNav ul .active i,
.sideNav ul li:hover a i {
  color: #0353a4 !important;
}
.activex {
  color: #0353a4 !important;
  border-bottom: 2px solid #0353a4;
}

.activex a {
  color: #0353a4 !important;
}

.Toastify .Toastify__toast-container {
  z-index: 999999999999999 !important;
}

.Toastify__toast--success {
  background: #223890 !important;
}


.radio [type=radio] {
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
.radio [type=radio]+img {
  cursor: pointer;
}

/* CHECKED STYLES */
.radio [type=radio]:checked+img {
  outline: 2px solid #d2d2d2;
  background: #fff !important;
  border-radius: 20px;
  box-shadow: 0px 0px 28px 6px rgba(0, 0, 0, 0.09);

}



.profileBox {
  background: #005751;
  min-height: 200px;
  padding-top: 30px
}


.homeBoard {

  min-height: 400px;

  background-size: contain;
  margin-top: 120px;
  border-radius: 20px;
  padding-left: 2px;
  padding-right: 2px;

}



.Modal {
  position: fixed;
  z-index: 9999999999;
  background-color: #fff;
  width: 100%;
  border: 1px solid #ccc;
  -webkit-box-shadow: 1px 1px 1px #000;
  box-shadow: 1px 1px 1px #000;
  padding: 16px;
  left: 0;
  right: 0;
  top: 0%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* transition: all 1.3s ease; */
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
}

.ModalCenter {
  position: fixed;
  z-index: 9999999;
  background-color: #fff;
  width: 100%;
  border: 1px solid #ccc;
  -webkit-box-shadow: 1px 1px 1px #000;
  box-shadow: 1px 1px 1px #000;
  padding: 16px;
  left: 0;
  right: 0;
  top: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 300px;
  max-height: 100vh;
  overflow-y: scroll;
}

.ModalLoading {
  position: fixed;
  z-index: 9999999;
  background-color: none;
  width: 100%;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 16px;
  left: 0;
  right: 0;
  top: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 500px;
  max-height: 100vh;
}

@media (min-width: 600px) {
  .Modal {
    width: 400px;
    left: 100%;
    /* right: 100%; */
    margin-left: -400px;
    /* right: 50%; */
  }

  .ModalCenter {
    width: 400px;
    max-width: 100vw;
    margin: auto;
  }
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.85);
}

.width100 {
  width: 100% !important;
}




.l-radio {
  padding: 6px;
  border-radius: 50px;
  display: inline-flex;
  cursor: pointer;
  transition: background 0.2s ease;
  margin: 8px 0;
  -webkit-tap-highlight-color: transparent;
}

.l-radio:hover,
.l-radio:focus-within {
  background: rgba(159, 159, 159, 0.1);
}

.l-radio input {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: none;
  border: 0;
  box-shadow: inset 0 0 0 1px #9F9F9F;
  box-shadow: inset 0 0 0 1.5px #9F9F9F;
  appearance: none;
  padding: 0;
  margin: 0;
  transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
  pointer-events: none;
}

.l-radio input:focus {
  outline: none;
}

.l-radio input:checked {
  box-shadow: inset 0 0 0 6px #6743ee;
}

.l-radio span {
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
  padding: 0 8px;
}

.formBorder {
  border-color: #969696 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}

.checkbox-wrapper-1 [type=checkbox].substituted {
  margin: 0;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.checkbox-wrapper-1 [type=checkbox].substituted+label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.6em;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset, 0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>') no-repeat center, white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
}

.checkbox-wrapper-1 [type=checkbox].substituted:enabled:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted:enabled+label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
  background-color: #f0f0f0;
}

.checkbox-wrapper-1 [type=checkbox].substituted:checked+label:before {
  background-color: #3B99FC;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}

.checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled+label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}

.checkbox-wrapper-1 [type=checkbox].substituted:focus+label:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
}

.checkbox-wrapper-1 [type=checkbox].substituted:focus:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted:focus+label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
}

.checkbox-wrapper-1 [type=checkbox].substituted:disabled+label:before {
  opacity: 0.5;
}

.checkbox-wrapper-1 [type=checkbox].substituted.dark+label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}

.checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled+label:active:before {
  background-color: #444;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
}

.checkbox-wrapper-1 [type=checkbox].substituted.dark:checked+label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}

.checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled+label:active:before {
  background-color: #c68035;
  color: rgba(0, 0, 0, 0.275);
}







.flexer,
.progress-indicator {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}

.no-flexer,
.progress-indicator.stacked {
  display: block;
}

.no-flexer-element {
  -ms-flex: 0;
  -webkit-flex: 0;
  -moz-flex: 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0;
  -ms-flex: 0;
  flex: 0;
}

.flexer-element,
.progress-indicator>li {
  -ms-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.progress-indicator {
  margin: 0 0 1em;
  padding: 0;
}

.progress-indicator>li {
  list-style: none;
  text-align: center;
  width: auto;
  padding: 0;
  margin: 0;
  position: relative;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: #bbb;
  display: block;
}

.progress-indicator>li:hover {
  color: #6f6f6f;
}

.progress-indicator>li.completed,
.progress-indicator>li.completed .bubble {
  color: #223890;
  margin-top: 0.9px;
  
}

.progress-indicator>li.completedSecondary,
.progress-indicator>li.completedSecondary .bubble {
  color: #b7974a;
}

.bubble,
.innerBuble {
  z-index: 999999999999999 !important;
}

.progress-indicator>li .bubble {
  border-radius: 1000px;
  width: 40px;
  height: 40px;
  display: block;
  margin: 0 auto 0.5em;
  /* border: 2px solid #8b8b8b; */
  z-index: 999999999999 !important;
  padding: 1px;
  color: #fff !important;
}

.progress-indicator>li .bubble .innerBubble {
  border-radius: 1000px;
  width: 30px;
  height: 30px;
  display: block;
  margin: 1.9px;
  background: #8b8b8b;
  z-index: 99999 !important;
  padding-top: 2px;
  color: #fff !important;
  font-weight: 800;
}

.completedInner {
  background: #223890 !important;
}

.progress-indicator>li .completedInnerx:after,
.progress-indicator>li .completedInnerx:before{

  background-color: #223890 !important;
}

.progress-indicator>li .bubble:after,
.progress-indicator>li .bubble:before {
  display: block;
  position: absolute;
  width: 200px;
  height: 5px;
  content: "";
  background-color: #bbb;
  margin-top: 8px;
  padding-bottom: 1px;
  margin-top: 17px;
  z-index: -1 !important;
}

.progress-indicator >li.completed:last-child .completedInnerx:before {

  background: #223890 !important;
}

.progress-indicator>li:last-child .bubble:before {
  width: 100%;
  /* margin-right: 50%; */
  height: 5px;
  background: #bbb !important;
  z-index: -1 !important;

}

.progress-indicator>li.completedSecondary .bubble,
.progress-indicator>li.completedSecondary .bubble:after,
.progress-indicator>li.completedSecondary .bubble:before {
  background-color: #b7974a;
  border-color: #b7974a;
}

.progress-indicator>li .bubble:before {
  left: 0;
}

.progress-indicator>li .bubble:after {
  z-index: 0;
  display: block;
  margin-top: -16.5px;
}

.progress-indicator>li:first-child .bubble:before {
  width: 50%;
  /* margin-left: 50%; */
  z-index: 0;
  /* margin-left: 10px; */
}

.progress-indicator>li:last-child .bubble:after,
.progress-indicator>li:last-child .bubble:before {
  /* width: 50%; */
  /* margin-right: 50%; */
  background: none;
}

.progress-indicator>li.active,
.progress-indicator>li.active .bubble {
  color: #337ab7;
}

.progress-indicator>li.active .bubble,
.progress-indicator>li.active .bubble:after,
.progress-indicator>li.active .bubble:before {
  background-color: #337ab7 !important;
  border-color: #122a3f;
}

.progress-indicator>li a:hover .bubble,
.progress-indicator>li a:hover .bubble:after,
.progress-indicator>li a:hover .bubble:before {
  background-color: #5671d0;
  border-color: #1f306e;
}

.progress-indicator>li a:hover .bubble {
  color: #5671d0;
}

.progress-indicator>li.danger .bubble,
.progress-indicator>li.danger .bubble:after,
.progress-indicator>li.danger .bubble:before {
  background-color: #d3140f;
  border-color: #440605;
}

.progress-indicator>li.danger .bubble {
  color: #d3140f;
}

.progress-indicator>li.warning .bubble,
.progress-indicator>li.warning .bubble:after,
.progress-indicator>li.warning .bubble:before {
  background-color: #edb10a;
  border-color: #5a4304;
}

.progress-indicator>li.warning .bubble {
  color: #edb10a;
}

.progress-indicator>li.info .bubble,
.progress-indicator>li.info .bubble:after,
.progress-indicator>li.info .bubble:before {
  background-color: #5b32d6;
  border-color: #25135d;
}

.progress-indicator>li.info .bubble {
  color: #5b32d6;
}

.progress-indicator.stacked>li {
  text-indent: -10px;
  text-align: center;
  display: block;
}

.progress-indicator.stacked>li .bubble:after,
.progress-indicator.stacked>li .bubble:before {
  left: 10px;
  margin-left: -1.5px;
  width: 3px;
  height: 100%;
}

.progress-indicator.stacked .stacked-text {
  position: relative;
  z-index: 10;
  top: -32px;
  width: 45% !important;
  display: inline-block;
  text-align: left;
}

.progress-indicator.stacked>li a {
  border: none;
}

.progress-indicator.stacked.nocenter>li .bubble {
  margin-left: 0;
  margin-right: 0;
}

.progress-indicator.stacked.nocenter>li .bubble:after,
.progress-indicator.stacked.nocenter>li .bubble:before {
  left: 10px;
}

.progress-indicator.stacked.nocenter .stacked-text {
  width: auto !important;
  display: block;
  margin-left: 50px !important;
}

@media handheld,
screen and (max-width: 400px) {
  .progress-indicator {
    font-size: 60%;
  }
}


.landing-next.swiper-button-disabled,
.landing-prev.swiper-button-disabled {
  opacity: 0.4;
}



.textColor{
  color: #1D1B1B !important
}


.primaryColor  .navlink{

  color: #263C93 !important !important;

}

#drp{

  color: #263C93 !important !important;

}

.navbar-light .navbar-nav .nav-link{
  color: #263C93 !important;
}