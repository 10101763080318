@media only screen and (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 1rem !important;
      padding-left: 1 rem !important;
    }
  }
  @media only screen and (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 1rem !important;
      padding-left: 1 rem !important;
    }
  }
  @media only screen and (min-width: 600px) {
    .visible-xs {
      display: none !important;
    }
    .mobileView {
      display: none !important;
      position: relative;
      height: 100px;
    }

   
  }
  @media only screen and (max-width: 800px) {
    .visible-xs {
      display: block !important;
    }
    .mt--100 {
      margin-top: 0 !important;
    }
    .myNav {
      width: 200px;
      height: 100vh;
      width: 0;
     
      top: 0;
      bottom: 0;
      left: 0px !important;
      /* padding-top: 30px; */
      text-decoration: none !important;
    }
    .mainBody {
      background: #f2f2f2;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: fixed;
    }
    .mobileView {
      display: visible !important;
      position: relative;
      height: 50px;
      bottom: 0;
      top: 0;
      left: 0;
    }
    .cardx {
      width: 100%;
      margin-left: 0 !important;
      margin-top: 0 !important;
      padding: 20px;
    }
    .activex {
      color: #0353a4 !important;
      border-bottom: none !important;
    }
    .dashboardx .swiper-pagination-bullet {
      display: inline-block !important;
    }
    .swiper-pagination-bullet {
      width: 10px !important;
      height: 4px !important;
      display: inline-block !important;
      border-radius: 0 !important;
      background: #000;
      opacity: 0.2;
    }
    .swiper-pagination-bullet-active {
      background: #0353a4 !important;
      opacity: 1 !important;
    }
    .pincode-input-text {
      border-bottom: 2px solid #75140c !important;
      width: 33px !important;
    }
    .mapView {
      height: 50vh;
    }
    .moveLeft {
      margin-left: -10px !important;
    }
    .mlAvatar {
      margin-left: -20px !important !important !important;
      width: 33px !important;
      height: 33px !important;
      display: block !important;
      position: absolute !important;
    }
    .mlAvatar span {
      margin-top: -4px !important;
      display: block;
    }

    .textCenter{
      text-align: center;
    }
  }
  